.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
}

.tags .tag {
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0.5em;
  border: 0.01em solid #23A7DF;
  border-radius: 0.25em;
  background-color: #23A7DF;
  font-size: 12px;
}

.tags .tag:first-child {
  margin-left: 0;
}

div[dir=ltr][class=lang] .tags .tag {
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
}

div[dir=rtl][class=lang] .tags .tag {
  border-bottom-right-radius: 1em;
  border-top-right-radius: 1em;
}

.tags .tag .main {
  display: inline-block;
  padding: 0.3em;
  color: #fff;
}

div[dir=ltr][class=lang] .tags .tag .main {
  margin-left: 1em;
}

div[dir=rtl][class=lang] .tags .tag .main {
  margin-right: 1em;
}

.tags .tag .state {
  cursor: pointer;
  padding: 0.3em;
}

div[dir=ltr][class=lang] .tags .tag .state {
  border-left: 0.01em solid #fff;
}

div[dir=rtl][class=lang] .tags .tag .state {
  border-right: 0.01em solid #fff;
}

.tags .tag .follow {
  color: #000;
  opacity: 0.75;
}

.tags .tag .following {
  color: #fff;
  opacity: 0.75;
}

.tags .tag .updating {
  background-color: #4D4D4D;
}

.tags .tag .main::before {
  position: absolute;
  border-radius: 0.25em;
  background-color: #fff;
  content: "";
}

div[dir=ltr][class=lang] .tags .tag .main::before {
  top: 0.6em;
  height: 0.45em;
  width: 0.45em;
  left: 0.5em;
}

div[dir=rtl][class=lang] .tags .tag .main::before {
  top: 0.75em;
  height: 0.5em;
  width: 0.5em;
  right: 0.5em;
}
