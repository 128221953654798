.header {
  color: #000;
  display: flex;
  align-content: center;
  flex-direction: row;
  padding: 0.5em 1em 0.5em 0;
  margin: 0 0 0.5em 0;
}

.header svg {
  font-size: 2em;
}

.icon {
  margin-top: auto;
  margin-bottom: auto;
 
  font-size: 2.5em;
}
.txt {
  font-size: 1.25em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-top: auto;
  margin-bottom: auto;
}

.description {
  color: #23A7DF;
  padding: 5px 5px 5px 10px;
  margin-bottom: 1em;
  display: flex;
  border-left: 3px solid #23A7DF;
  background-color: #ffffff;
}

.menuwrapper {
  margin-left: auto;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  font-size: 2em;
  color: #4d4d4d;
  padding: 0em;
}
.menu {
  display: flex;
  font-size: 0.5em;
  align-content: center;
  white-space: nowrap;
}

.menuicon {
  font-size: 1.25em;
  margin-left: 0.5em;
 
  margin-bottom: 25px;
}
.main_article {
  padding-top: 0;
  display: block;
  margin: 0;
}

article[dir=rtl] {
  font-size:1.1em;
}

.article > * {
  display: block;


}

.article > span, .article h2, .article ul, .article ol {
  padding-left: 5vw;
  padding-right: 5vw;
  margin-left: auto;
  margin-right: auto;
}

@media (pointer: coarse) {
  .article > span, .article h2, .article ul, .article ol {
    max-width: 90vw;
  }
}

@media (pointer: fine) {
  .article > span, .article h2, .article ul, .article ol {
    max-width: 75vw;
  }
}

.article li {
  margin-left: 2em;
  margin-right: 2em;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.article blockquote  {
  color: #999;
  font-style: italic;
  padding-left: 10vw;
  padding-right: 10vw;
  margin-left: auto;
  margin-right: auto;
  max-width: 640px
}

.article .mention {
  color: #999999;
  background-color: #dceddd;
}

.article .link {
  color: #999999;
  text-decoration: underline;
}

.article .superscript {
  font-size: 0.6em;
  vertical-align: super;
  margin-left: 0.25em;
  margin-right: 0.25em;
}



