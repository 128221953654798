.response_tools {
  margin-top: 4px;
  display: flex;
  white-space: nowrap;
}

.menu {
  display: flex;
  font-size: 0.5em;
  align-content: center;
  white-space: nowrap;
}

.reopen {
  margin-left: auto;
  display: flex;
  flex-direction: row-reverse;
}

.menuwrapper {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 130px);
}
