.share_post_dialog {
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.share_post_dialog a {
  font-size: 0.8em;
}

.share_post_dialog .url, .share_post_dialog span {
  margin: 1em;
  color: #999999;
}

.share_post_dialog .url {
  margin-top: 1.5em;
}
